import Panel from '../../../../components/Panel';

export default function Gallery() {
  return (
    <Panel>
      <div className="gallery">
        <div className="group span-4-5">
          <a
            href="images/derbystars/DS_Horse_U005_A.png"
            className="image filtered span-3"
            data-position="center"
          >
            <img src="images/derbystars/DS_Horse_U005_A.png" alt="" />
          </a>
          <a
            href="images/derbystars/DS_Horse_U011_A.png"
            className="image filtered span-1-5"
            data-position="center"
          >
            <img src="images/derbystars/DS_Horse_U011_A.png" alt="" />
          </a>
          <a
            href="#"
            className="image filtered span-1-5"
            data-position="center"
          >
            <img src="images/derbystars/DS_Horse_U012_A.png" alt="" />
          </a>
          <a href="#" className="image filtered span-3" data-position="center">
            <img src="images/derbystars/DS_Horse_U006_A.png" alt="" />
          </a>
        </div>
      </div>
    </Panel>
  );
}
