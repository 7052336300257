import Panel from '../../../../components/Panel';
import { useEthers } from '@usedapp/core';
import { useState, useMemo } from 'react';
import { useLocation } from 'react-router';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { UnopndSouvenirV1__factory } from 'contracts';
import Loader from 'react-spinners/PulseLoader';

const walletconnect = new WalletConnectConnector({
  rpc: {
    1:
      process.env.WEB3_API ||
      'https://eth-mainnet.alchemyapi.io/v2/4rAEz-P-nmNvmZQmPZdoLn1r-dsnav_j',
  },
  qrcode: true,
});

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function Mint() {
  const { activateBrowserWallet, activate, deactivate, library, account } =
    useEthers();
  const [pendingTx, setPendingTx] = useState<string>();

  // const [email, setEmail] = useState<string>();
  const q = useQuery();
  const id = q.get('id');
  const emailHash = q.get('emailHash');
  const sig = q.get('sig');

  return (
    <Panel alignment={'right'} style={{ backgroundColor: 'black' }} id="mint">
      <div className="inner columns aligned">
        <div className="span-3-25">
          <h2 className="major">
            Unopnd Souvenir: <a href="/">Decentral Miami 2021!</a>
          </h2>
          {!account && (
            <div>
              <ul className="actions">
                <li>
                  <button onClick={() => activateBrowserWallet()}>
                    Metamask
                  </button>
                </li>
                <li>
                  <button onClick={() => activate(walletconnect)}>
                    Wallet Connect
                  </button>
                </li>
              </ul>
            </div>
          )}
          {account && (
            <div>
              <p>Connected: {account}</p>
              <ul className="actions">
                <li>
                  <button onClick={() => deactivate()}>Disconnect</button>
                </li>
                <li>
                  <button
                    onClick={async () => {
                      if (!library) {
                        alert('Connect the wallet first');
                      } else {
                        if (id && emailHash && sig) {
                          const signer = library.getSigner();
                          const contract = UnopndSouvenirV1__factory.connect(
                            process.env.CONTRACT_ADDRESS ||
                              '0x471B9F40fd746290E988a28E53a20A3ede69aef1',
                            library,
                          );
                          try {
                            await contract
                              .connect(signer)
                              .estimateGas.mint(id, emailHash, sig);
                            contract
                              .connect(signer)
                              .mint(id, emailHash, sig)
                              .then((tx) => {
                                setPendingTx(tx.hash);
                                tx.wait().then(() => {
                                  setPendingTx(undefined);
                                  alert('Successfully minted!');
                                });
                              });
                          } catch (e) {
                            const message = (e as any).error.message;
                            alert(message);
                          }
                        } else {
                          alert(
                            'You need a redeem code to mint this NFT. Please subscribe Unopnd Newsletter and get a redeem code!',
                          );
                        }
                      }
                    }}
                  >
                    Mint my souvenir!
                  </button>
                </li>
              </ul>
            </div>
          )}
          {pendingTx && (
            <div>
              <p>Pending: {pendingTx}</p>
              <Loader
                color={'white'}
                loading={true}
                size={15}
                margin={4}
                speedMultiplier={0.5}
              />
            </div>
          )}
        </div>
      </div>
    </Panel>
  );
}
