import Panel from '../../../../components/Panel';

export default function Details() {
  return (
    <Panel alignment={'right'} color={'color4'}>
      <div className="inner columns aligned">
        <div className="span-5">
          <h2 className="major">Story</h2>
          <p>
            In the future, scientists revealed that the origins of the universe
            and humans were actually digital zeros and ones. Human beings
            started to upgrade their bodies with digital humanoids, and finite
            life has become a matter of choice. Medicine and religion
            disappeared, and humanity has expanded into space. Human life soon
            became prosperous, but their individuality is gone, because
            everybody’s physical body became standardized into a similar
            appearance. It's natural that they become obsessed with possessing
            and celebrating the finite and rare. Some of them were active in
            these activities, and used to gather items around the universe. A
            few of them, who became general managers, joined their forces to
            establish a space hotel, called '0xdava' . The entire building of
            this hotel could appear in various spaces, just like a portal. The
            founders brought various items from all over the universe and
            started introducing them to people. Those items were evidence of
            experience, and also demonstrated pride in taste. Among them were
            some bizarre and funny items from unknown places, and some nostalgic
            collectibles from the Earth. Soon, 0xdava quickly became famous and
            a popular place all over the universe. Now they are creating a
            membership called DAVA, and started various activities to make the
            entire universe a more beautiful and exciting place...
          </p>
        </div>
        <div className="span-3-5">
          <h2 className="major">Team</h2>
          <p>
            <strong>sugarpah</strong>: Product Manager. Former director of
            product @educast
            <br />
            <strong>ggomma</strong>: Contract Developer. Former mainnet
            developer @MediBloc
            <br />
            <strong>Vandal</strong>: Art Director. Artist w/ serial
            entrepreneurship experience.
            <br />
            <strong>Journey</strong>: Frontend Developer. Former senior frontend
            developer @Classting
            <br />
            <strong>Wintermute</strong>: Community Manager. Community builder w/
            5yrs+ experience.
          </p>
        </div>
      </div>
    </Panel>
  );
}
