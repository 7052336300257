import Panel from '../../../../components/Panel';

const NFTViewer = () => (
  <Panel style={{ backgroundColor: 'black' }}>
    <div className="inner columns aligned">
      <div className="span-1-25">
        <ul className="contact-icons">
          <li className="icon brands fa-twitter">
            <a href="https://twitter.com/unopnd_official">Twitter</a>
          </li>
          {/* <li className="icon brands fa-facebook-f">
            <a href="#">Facebook</a>
          </li>
          <li className="icon brands fa-instagram">
            <a href="#">Instagram</a>
          </li> */}
          <li className="icon brands fa-github">
            <a href="https://github.com/unopnd">Github</a>
          </li>
          {/* <li className="icon brands fa-discord-m">
            <a href="#">Discord</a>
          </li> */}
        </ul>
      </div>
    </div>
  </Panel>
);

export default NFTViewer;
