import { forwardRef } from 'react';
import Panel from '../../../../components/Panel';

const Hero = forwardRef<HTMLDivElement>((props, ref) => (
  <Panel size="large" spotlight alignment={'right'} {...props} ref={ref}>
    <div className="content span-5">
      <h1>
        <img src="images/DERBY.png" style={{ width: '100%' }} />
      </h1>
    </div>
    <div className="image tinted" data-position="top right">
      <img src="images/DerbyStars.png" alt="" />
    </div>
  </Panel>
));

export default Hero;
