import Panel from '../../../../components/Panel';

export default function Gallery() {
  return (
    <Panel>
      <div className="gallery">
        <div className="group span-10">
          <a
            href="images/gallery/fulls/05.jpg"
            className="image span-6"
            data-position="bottom"
          >
            <img src="images/derbystars/DS_IR_RunningType.png" alt="" />
          </a>
          <a href="#" className="image span-4" data-position="center">
            <img src="images/derbystars/DS_Wallpaper_Running_01.png" alt="" />
          </a>
          <a href="#" className="image span-1-5" data-position="right">
            <img
              src="images/derbystars/DS_Wallpaper_Chacter_Horse.png"
              alt=""
            />
          </a>
          <a href="#" className="image span-4" data-position="left">
            <img src="images/derbystars/DS_Wallpaper_Running_02.png" alt="" />
          </a>

          <a href="#" className="image span-1-5" data-position="right">
            <img src="images/derbystars/DS_Wallpaper_Chacter_02.png" alt="" />
          </a>
          <a href="#" className="image span-3" data-position="center">
            <img src="images/derbystars/DS_Wallpaper_Night.png" alt="" />
          </a>
        </div>
      </div>
    </Panel>
  );
}
