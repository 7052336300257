import Panel from '../../../../components/Panel';
import { useState } from 'react';

export default function Subscription() {
  const [email, setEmail] = useState<string>();
  return (
    <Panel alignment={'right'} color="color0" id="subscription">
      <div className="inner columns aligned">
        <div className="span-3-25">
          <h2 className="major">Subscribe & get a special souvenir NFT!</h2>
          <form
            action="#"
            onSubmit={(e) => {
              e.preventDefault();
              fetch('https://api.miami2021.unopnd.com/subscription', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
              })
                .then(() => {
                  alert(
                    'Thanks for your subscription! Go to your mail box and mint an NFT!',
                  );
                })
                .catch((e) => console.error(e));
            }}
          >
            <div className="fields">
              <div className="field">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete={'off'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Subscribe" className="button" />
              </li>
            </ul>
          </form>
        </div>
      </div>
    </Panel>
  );
}
