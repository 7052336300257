import { RefObject } from 'react';
import styled from 'styled-components';

const Div = styled.div`
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 5rem;
  width: 100vw;
  height: wrap-content;
`;

const Img = styled.img`
  width: 10%;
  background: black;
  margin: 5px;
`;

interface Icon {
  ref: RefObject<HTMLDivElement>;
  img: string;
}

export interface MenuBarProps extends React.HTMLAttributes<HTMLElement> {
  home: RefObject<HTMLDivElement>;
  derby: RefObject<HTMLDivElement>;
  dava: RefObject<HTMLDivElement>;
  vrism: RefObject<HTMLDivElement>;
  passTheBaton: RefObject<HTMLDivElement>;
  lok: RefObject<HTMLDivElement>;
  children?: any;
}

export default function MenuBar({
  home,
  derby,
  dava,
  vrism,
  passTheBaton,
  lok,
  ...rest
}: MenuBarProps) {
  const icons: Icon[] = [
    { ref: home, img: 'images/menus/home.png' },
    { ref: derby, img: 'images/menus/derby.png' },
    { ref: dava, img: 'images/menus/dava.png' },
    { ref: passTheBaton, img: 'images/menus/pbt.png' },
    { ref: vrism, img: 'images/menus/vrism.png' },
    { ref: lok, img: 'images/menus/lok.png' },
  ];
  return (
    <Div {...rest}>
      {icons.map((icon) => (
        <Img
          src={icon.img}
          onClick={() =>
            icon.ref.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          }
        />
      ))}
    </Div>
  );
}
