import ScrollContainer from 'react-indiana-drag-scroll';
import { createRef } from 'react';

export default function Page(props: { children: any }) {
  const container = createRef<HTMLDivElement>();
  return (
    <ScrollContainer
      className="scroll-container"
      horizontal
      style={{ width: '100vw', height: '100vh' }}
    >
      <div
        className="App"
        id="page-wrapper"
        style={{ overflowX: 'scroll' }}
        ref={container}
        onWheel={(e) => {
          if (container.current && e.deltaX === 0) {
            container.current.scrollBy({ left: e.deltaY });
          }
        }}
      >
        {props.children}
      </div>
    </ScrollContainer>
  );
}
