// import logo from '../../logo.svg';
import { ChainId, DAppProvider, Config } from '@usedapp/core';
import './App.css';
import Section from '../../components/Section';
import Mint from './sections/Mint/Mint';
import Social from './sections/Social/Social';
import NFTViewer from './sections/NFTViewer/NFTViewer';

const config: Config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]:
      process.env.WEB3_API ||
      'https://eth-mainnet.alchemyapi.io/v2/4rAEz-P-nmNvmZQmPZdoLn1r-dsnav_j',
  },
};

function App() {
  return (
    <DAppProvider config={config}>
      <div className="App" id="page-wrapper">
        <Section>
          <NFTViewer />
          <Mint />
          <Social />
        </Section>
      </div>
    </DAppProvider>
  );
}

export default App;
