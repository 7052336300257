import Panel from '../../../../components/Panel';

export default function Gallery() {
  return (
    <Panel>
      <div className="gallery">
        <a href="#" className="image span-3-5" data-position="center">
          <img src="images/vrism/001.png" alt="" />
        </a>
      </div>
    </Panel>
  );
}
