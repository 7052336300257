import Panel from '../../../../components/Panel';

export default function Gallery() {
  return (
    <Panel>
      <div className="gallery">
        <a href="#" className="image span-2-25" data-position="left">
          <img src="images/ptb/ptb_miami_iPad_batontemplate_1.png" alt="" />
        </a>
        <a href="#" className="image span-2-25" data-position="left">
          <img src="images/ptb/ptb_miami_iPad_batontemplate_2.png" alt="" />
        </a>
        <a href="#" className="image span-2-25" data-position="left">
          <img src="images/ptb/ptb_miami_iPad_batontemplate_3.png" alt="" />
        </a>
        <a href="#" className="image span-2-25" data-position="left">
          <img src="images/ptb/ptb_miami_iPad_batontemplate_4.png" alt="" />
        </a>
        <a href="#" className="image span-2-25" data-position="left">
          <img src="images/ptb/ptb_miami_iPad_batontemplate_5.png" alt="" />
        </a>
        <div className="group span-4">
          <a href="#" className="image span-1" data-position="center">
            <img src="images/ptb/ptb_miami_iPad_solobaton_1.png" alt="" />
          </a>
          <a href="#" className="image span-1" data-position="center">
            <img src="images/ptb/ptb_miami_iPad_solobaton_2.png" alt="" />
          </a>
          <a href="#" className="image span-1" data-position="center">
            <img src="images/ptb/ptb_miami_iPad_solobaton_3.png" alt="" />
          </a>
          <a href="#" className="image span-1" data-position="center">
            <img src="images/ptb/ptb_miami_iPad_solobaton_4.png" alt="" />
          </a>
          <a href="#" className="image span-1" data-position="center">
            <img src="images/ptb/ptb_miami_iPad_solobaton_5.png" alt="" />
          </a>
          <a href="#" className="image span-1" data-position="center">
            <img src="images/ptb/ptb_miami_iPad_solobaton_6.png" alt="" />
          </a>
          <a href="#" className="image span-1" data-position="center">
            <img src="images/ptb/ptb_miami_iPad_solobaton_7.png" alt="" />
          </a>
          <a href="#" className="image span-1" data-position="center">
            <img src="images/ptb/ptb_miami_iPad_solobaton_8.png" alt="" />
          </a>
        </div>
        <a href="#" className="image span-2" data-position="center">
          <img src="images/ptb/ptb_miami_iPad_solobaton_9.png" alt="" />
        </a>
      </div>
    </Panel>
  );
}
