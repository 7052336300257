import { forwardRef } from 'react';
import Panel from '../../../../components/Panel';

const Hero = forwardRef<HTMLDivElement>((props, ref) => (
  <Panel size="large" spotlight alignment={'left'} {...props} ref={ref}>
    <div className="content span-7" data-position="center">
      {/* <h1>
        <img
          src="images/dava/davaproject_logo_wht.png"
          style={{ width: '100%' }}
        />
      </h1> */}
    </div>
    <div className="image tinted" data-position="top right">
      <img
        src="images/dava/davaproject_image_shot.png"
        alt=""
        style={{ background: 'black' }}
      />
    </div>
  </Panel>
));

export default Hero;
