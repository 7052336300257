import Panel from '../../../../components/Panel';

export default function Gallery() {
  return (
    <Panel>
      <div className="gallery">
        <div className="group span-8">
          <a href="#" className="image span-8" data-position="center">
            <img src="images/dava/davaproject_image_big_grid.png" alt="" />
          </a>
          <a href="#" className="image span-3" data-position="center">
            <img src="images/dava/davaproject_image_0xdava_ui.png" alt="" />
          </a>
          <a href="#" className="image span-2" data-position="left">
            <img src="images/dava/davaproject_image_0xdava_scene.png" alt="" />
          </a>
          <a href="#" className="image span-3" data-position="center">
            <img src="images/dava/davaproject_image_concept.png" alt="" />
          </a>
        </div>
        <a href="#" className="image span-4-5" data-position="left">
          <img src="images/dava/davaproject_image_0xdava_scene2.png" alt="" />
        </a>
      </div>
    </Panel>
  );
}
