import { forwardRef } from 'react';
import classNames from 'classnames';
import { Color } from './colors';

export type PanelSize = 'small' | 'medium' | 'large';
export type PanelAlignment = 'left' | 'right';

export interface PanelProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  color?: Color;
  size?: PanelSize;
  alignment?: PanelAlignment;
  banner?: boolean;
  spotlight?: boolean;
  children?: any;
}

// interface Panel

const Panel = forwardRef<HTMLDivElement, PanelProps>((props, ref) => {
  const { size, alignment, banner, spotlight, color, children, ...rest } =
    props;
  return (
    <div
      className={classNames(
        'panel',
        size,
        alignment,
        banner && 'banner',
        spotlight && 'spotlight',
        color,
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </div>
  );
});

export default Panel;
