import Panel from '../../../../components/Panel';
import styled from 'styled-components';

const Img = styled.img`
  vertical-align: top;
  margin: 10px;
`;

export default function IntroDerbyStars() {
  return (
    <Panel banner alignment={'left'} color="color0">
      <div className="content span-5" style={{ background: 'black' }}>
        <h2 className="major">
          <img src="images/PTB.png" alt="" style={{ height: '3rem' }} />
        </h2>
        <p>
          Pass the Baton is a revolutionary digital art project by artist Glenn
          Kaino and his decade-long collaborator, Tommie Smith, the iconic
          Olympian who broke 13 world records before standing in an
          unforgettable silent protest at the 1968 Olympic Games.The project
          includes 7,862 digitally-rendered baton NFTs inspired by Tommie
          Smith’s coveted sculptural artefact: the baton he usedin four
          record-breaking races. Each baton features a team of names that will
          join Tommie Smithto form a Legacy Team—including activists,
          advocates,and changemakers like Megan Rapinoe, Minyon Moore, Jesse
          Williams, Derrick Johnson, and Brittany Packnett Cunningham. There
          will be four Legacy Teams.
        </p>
        <div>
          <Img src="images/Twitter_Icon.png" style={{ height: '2rem' }} />
          <Img src="images/qr/PTB_Twitter.png" style={{ height: '5rem' }} />
        </div>
      </div>
    </Panel>
  );
}
