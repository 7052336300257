import Panel from '../../../../components/Panel';

export default function Hero() {
  return (
    <Panel banner alignment={'left'}>
      <div className="image span-7" data-position="top center">
        <img
          src="images/lok/LOK_L3.png"
          alt=""
          style={{ background: 'black', height: '100%' }}
        />
      </div>
    </Panel>
  );
}
