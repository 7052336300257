import { forwardRef } from 'react';
import Panel from '../../../../components/Panel';

const Hero = forwardRef<HTMLDivElement>((props, ref) => (
  <Panel banner alignment={'left'} {...props} ref={ref}>
    <div className="span-7 image tinted color2" data-position="top center">
      <img src="images/ptb/wallpaper.png" alt="" />
    </div>
  </Panel>
));

export default Hero;
