import Panel from '../../../../components/Panel';
import styled from 'styled-components';

const Img = styled.img`
  vertical-align: top;
  margin: 10px;
`;

export default function IntroHashed() {
  return (
    <Panel banner alignment={'left'}>
      <div className="inner columns aligned color4 ">
        <div className="content span-7">
          <h1 className="major">
            <img src="images/Hashed.png" alt="" style={{ height: '2rem' }} />
          </h1>
          <p>
            Hashed is an investment firm that focuses on blockchain. We take
            pride in ourselves that we contribute to a decentralized future
            through our investment, community building, and support. Our efforts
            allow our portfolio companies to penetrate the global market
            seamlessly to bootstrap their communities and the company-building
            process. As an early investor to Kakao’s Klaytn and Line’s LINK,
            Hashed has invested early in the blockchain projects of Asia tech
            giants. Also, Hashed was the early investor of some of the most
            significant projects in the blockchain space, including Terra, Axie
            Infinity, The Sandbox, etc. We run ‘Hashed Lounge’ for early
            blockchain projects for their co-working and offline meet-up space.
          </p>
          <div>
            <Img src="images/Twitter_Icon.png" style={{ height: '2rem' }} />
            <Img
              src="images/qr/Hashed_Twitter.png"
              style={{ height: '5rem' }}
            />
            <Img src="images/Homepage_Icon.png" style={{ height: '2rem' }} />
            <Img
              src="images/qr/Hashed_Homepage.png"
              style={{ height: '5rem' }}
            />
          </div>
        </div>
        <div className="content span-3-5">
          <h1 className="major">
            <img src="images/UNOPND.png" alt="" style={{ height: '2rem' }} />
          </h1>
          <p>
            UNOPND is a startup studio for creative projects, whose primary
            activity is exploring and experimenting with new ideas to apply
            blockchain in gaming and collectible NFTs. UNOPND aims to support
            innovative projects with the full breadth of expertise of its
            founders and the leadership members of Hashed.
          </p>
          <div>
            <Img src="images/Twitter_Icon.png" style={{ height: '2rem' }} />
            <Img
              src="images/qr/Unopnd_Twitter.png"
              style={{ height: '5rem' }}
            />
            <Img src="images/Homepage_Icon.png" style={{ height: '2rem' }} />
            <Img
              src="images/qr/Unopnd_Homepage.png"
              style={{ height: '5rem' }}
            />
          </div>
        </div>
      </div>
    </Panel>
  );
}
