import Panel from '../../../../components/Panel';
import styled from 'styled-components';

const Img = styled.img`
  vertical-align: top;
  margin: 10px;
`;

export default function IntroDerbyStars() {
  return (
    <Panel banner alignment={'left'}>
      <div className="content color1 span-5" style={{ background: 'black' }}>
        <h2 className="major">
          <img
            src="images/dava/davaproject_logo_wht.png"
            alt=""
            style={{ height: '3rem', marginLeft: '-1rem' }}
          />
        </h2>
        <p>
          ‘davaproject’ is building a decentralized avatar ecosystem. We're
          making an avatar that allows you to express yourself anywhere without
          being tied to a specific company or service. It can hold everything
          you need in a brand-new metaverse, like a 3D appearance, unique
          username, information, and assets. We’re building a sustainable
          ecosystem that rewards all participants on the blockchain with
          tokenomics. We're taking the first step towards our goal, and we
          present the first version of DAVA, in the form of a profile picture
          avatar with wearable NFTs.
        </p>
        <div>
          <Img src="images/Twitter_Icon.png" style={{ height: '2rem' }} />
          <Img src="images/qr/Dava_Twitter.png" style={{ height: '5rem' }} />
        </div>
      </div>
    </Panel>
  );
}
