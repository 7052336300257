import Panel from '../../../../components/Panel';
import styled from 'styled-components';

const Img = styled.img`
  vertical-align: top;
  margin: 10px;
`;

export default function Intro() {
  return (
    <Panel alignment={'right'} color={'color4'}>
      <div className="inner columns aligned">
        <div className="span-5">
          <h2 className="major">
            <img src="images/LOK.png" alt="" style={{ height: '3rem' }} />
          </h2>
          <p>
            League of Kingdoms is the world’s first and fastest-growing
            blockchain MMO strategy game. Not only the 4x strategy game offers
            various engaging game modes, but immersive social, economic, and
            political relations around alliance systems. The game is played
            cross-device, on Android, Apple, and web browser, by more than
            30,000 users daily from more than 210 countries around the globe.
          </p>
          <div>
            <Img src="images/Twitter_Icon.png" style={{ height: '2rem' }} />
            <Img src="images/qr/LoK_Twitter.png" style={{ height: '5rem' }} />
            <Img src="images/Homepage_Icon.png" style={{ height: '2rem' }} />
            <Img src="images/qr/LoK_Discord.png" style={{ height: '5rem' }} />
          </div>
        </div>
        <div className="span-3">
          <h2 className="major">$LOKA</h2>
          <p>
            League of Kingdoms has a robust economy with Non-Fungible Tokens
            (NFT) where gamers can play and earn by owning Lands or trading
            resources. With the upcoming adoption of LOKA token in December 2021
            and SOD token in Q1 2022, the economy will be elevated to the next
            level. The new tokenomics will blend into the existing game universe
            to power the compelling player-owned governance and Play-to-Earn
            game economy.
          </p>
        </div>
        <div className="span-3">
          <h2 className="major">Plan now!</h2>
          <p>
            Start by developing your kingdom, mastering cutting-edge
            technologies, training world-class armies, forge powerful alliances
            with other mighty kingdoms. Leverage your penetrating strategic and
            tactical acumen to lead your massive armies into epic battles
            against monsters, dragons, enemy kingdoms, and ultimately to
            victory.
          </p>
          <div>
            iOS
            <Img src="images/qr/LoK_iOS.png" style={{ height: '5rem' }} />
            &nbsp;Android
            <Img src="images/qr/LoK_Android.png" style={{ height: '5rem' }} />
            &nbsp;&nbsp;&nbsp;&nbsp;PC
            <Img src="images/qr/LoK_PC.png" style={{ height: '5rem' }} />
          </div>
        </div>
      </div>
    </Panel>
  );
}
