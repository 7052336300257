import { forwardRef } from 'react';
import Panel from '../../../../components/Panel';

const Hero = forwardRef<HTMLDivElement>((props, ref) => (
  <Panel size="large" spotlight alignment={'right'} {...props} ref={ref}>
    <div className="image tinted" data-position="top center">
      <img src="images/lok/LOK_L1.png" alt="" />
    </div>
  </Panel>
));

export default Hero;
