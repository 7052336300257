import Panel from '../../../../components/Panel';

export default function Details() {
  return (
    <Panel alignment={'right'} color="color4">
      <div className="inner columns aligned">
        <div className="span-3">
          <h2 className="major">First-ever NFT game on Terra!</h2>
          <ul>
            <li>Play-to-earn Fantasy Horse racing</li>
            <li>Player-Owned Economy (Gov & Util tokens)</li>
            <li>AAA-quality cartoon rendering graphic metaverse</li>
            <li>Variable NFTs : Horse, Avatar, Clothes, Land, Building</li>
          </ul>
          <h2 className="major">Roadmap</h2>
          <strong>2021. Q4</strong> : NFT genesis pre-sale (12/06 UTC-0, at
          Randomearth.io)
          <ul>
            <li>30 unique horses will be sold by auction.</li>
            <li>
              500 rare and 2470 common horses will be sold by random minting.{' '}
            </li>
          </ul>
          <strong>2022. Q1</strong> : Gov token IDO
          <br />
          <strong>2022. Q2</strong> : PvE training gameplay launching (Alpha
          version build)
          <br />
        </div>
      </div>
    </Panel>
  );
}
