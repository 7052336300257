import Panel from '../../../../components/Panel';
import styled from 'styled-components';

const Img = styled.img`
  vertical-align: top;
  margin: 10px;
`;

export default function IntroDerbyStars() {
  return (
    <Panel banner alignment={'left'} color="color0">
      <div className="content color1 span-5">
        <h2 className="major">
          <img
            src="images/DERBY_CROPPED.png"
            alt=""
            style={{ height: '3rem' }}
          />
        </h2>
        <p>
          Derby Stars is a horse racing metaverse game where players can breed,
          grow, build and trade. Derby Stars, which has been developed by game
          experts with decades of experience, will provide a spectacular NFT
          horses that have never been seen before. Derby development team is one
          of the most experienced teams for Unity engine and cartoon rendering
          graphics.
        </p>
        <div>
          <Img src="images/Twitter_Icon.png" style={{ height: '2rem' }} />
          <Img src="images/qr/Derby_Twitter.png" style={{ height: '5rem' }} />
        </div>
      </div>
    </Panel>
  );
}
