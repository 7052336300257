import Panel from '../../../../components/Panel';

const NFTViewer = () => (
  <Panel>
    <div className="gallery">
      <a href="#" className="image span-3-25" data-position="center">
        <img src="images/NFT.gif" alt="" />
      </a>
    </div>
  </Panel>
);

export default NFTViewer;
