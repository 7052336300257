import styled from 'styled-components';

const Div = styled.div`
  margin: 3rem;
`;

export default function Section(props: { children: any }) {
  const { children } = props;
  return <Div id="wrapper">{children}</Div>;
}
