// import logo from '../../logo.svg';
import './App.css';
import { createRef } from 'react';
import Page from '../../components/Page';
import MenuBar from '../../components/MenuBar';
import Section from '../../components/Section';
import LandingHero from './sections/Landing/Hero';
import IntroHashedUnopnd from './sections/Landing/Intro';
import DerbyStarsHero from './sections/DerbyStars/Hero';
import DerbyStarsIntro from './sections/DerbyStars/Intro';
import DerbyStarsDetails from './sections/DerbyStars/Details';
import DerbyStarsGallery0 from './sections/DerbyStars/Gallery0';
import DerbyStarsGallery1 from './sections/DerbyStars/Gallery1';
import DavaHero from './sections/DavaProject/Hero';
import DavaIntro from './sections/DavaProject/Intro';
import DavaDetails from './sections/DavaProject/Details';
import DavaGallery from './sections/DavaProject/Gallery';
import PassTheBatonHero from './sections/PassTheBaton/Hero';
import PassTheBatonIntro from './sections/PassTheBaton/Intro';
import PassTheBatonGallery from './sections/PassTheBaton/Gallery';
import VrismHero from './sections/Vrism/Hero';
import VrismIntro from './sections/Vrism/Intro';
import VrismGallery from './sections/Vrism/Gallery';
import LoKHero from './sections/LoK/Hero';
import LoKIntro from './sections/LoK/Intro';
import LoKDetails0 from './sections/LoK/Details0';
import LoKDetails1 from './sections/LoK/Details1';
import LoKDetails2 from './sections/LoK/Details2';
import Subscription from './sections/Subscription/Subscription';
// import LOKA from '../../sections/LoK/LOKA';

function App() {
  const [home, derby, dava, vrism, lok, passTheBaton] = new Array(6)
    .fill(null)
    .map(() => createRef<HTMLDivElement>());
  return (
    <Page>
      {/* <!-- Wrapper --> */}
      <MenuBar
        home={home}
        derby={derby}
        dava={dava}
        vrism={vrism}
        lok={lok}
        passTheBaton={passTheBaton}
      />

      <Section>
        {/* <!-- Panel (Banner) --> */}
        <LandingHero ref={home} />
      </Section>
      <Section>
        <IntroHashedUnopnd />
      </Section>
      <Section>
        <DerbyStarsHero ref={derby} />
        <DerbyStarsIntro />
        <DerbyStarsGallery0 />
        <DerbyStarsDetails />
        <DerbyStarsGallery1 />
      </Section>
      <Section>
        <DavaHero ref={dava} />
        <DavaIntro />
        <DavaGallery />
        <DavaDetails />
      </Section>
      <Section>
        <PassTheBatonHero ref={passTheBaton} />
        <PassTheBatonIntro />
        <PassTheBatonGallery />
      </Section>
      <Section>
        <VrismHero ref={vrism} />
        <VrismIntro />
        <VrismGallery />
      </Section>
      <Section>
        <LoKHero ref={lok} />
        <LoKIntro />
        {/* <LOKA /> */}
        <LoKDetails0 />
        <LoKDetails1 />
        <LoKDetails2 />
      </Section>
      <Section>
        <Subscription />
      </Section>
    </Page>
  );
}

export default App;
