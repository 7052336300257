import { forwardRef } from 'react';
import Panel from '../../../../components/Panel';

const Hero = forwardRef<HTMLDivElement>((props, ref) => (
  <Panel size="large" spotlight alignment={'left'} {...props} ref={ref}>
    <div className="content span-4">
      <h1>
        <img src="images/VRISM.png" />
      </h1>
    </div>
    <div className="image filtered tinted color2" data-position="top left">
      <img src="images/vrism/main.png" alt="" />
    </div>
  </Panel>
));

export default Hero;
