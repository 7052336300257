import Panel from '../../../../components/Panel';
import styled from 'styled-components';

const Img = styled.img`
  vertical-align: top;
  margin: 10px;
`;

export default function IntroDerbyStars() {
  return (
    <Panel banner alignment={'left'} color="color0">
      <div className="content color1 span-5">
        <h2 className="major">
          <img src="images/VRISM.png" alt="" style={{ height: '3rem' }} />
        </h2>
        <p>
          Hitchhiker is the 3D NFT sneakers collectibles that are
          algorithmically generated. The collection name 'Hitchhiker' was
          inspired by Douglas Adams' novel 'The Hitchhiker's Guide to the
          Galaxy' and contained the meaning of hoping that these sneakers will
          travel to every corner of the unknown in the infinite metaverse. This
          collection is the first Genesis Sneakers to be released and will play
          a significant role and function in the roadmap of the VRISM. The first
          VRISMERs to own a total of 777 unique designs will be able to enjoy a
          variety of benefits while sharing a mysterious andexciting journey in
          the future.
        </p>
        <div>
          <Img src="images/Twitter_Icon.png" style={{ height: '2rem' }} />
          <Img src="images/qr/Vrism_Twitter.png" style={{ height: '5rem' }} />
        </div>
      </div>
    </Panel>
  );
}
