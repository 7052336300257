import { forwardRef } from 'react';
import Panel from '../../../../components/Panel';

const Hero = forwardRef<HTMLDivElement>((props, ref) => (
  <Panel banner alignment={'left'} {...props} ref={ref}>
    <div className="image span-9" data-position="top right">
      <img
        src="images/Header.png"
        alt=""
        style={{ background: 'black', height: '100%' }}
      />
    </div>
  </Panel>
));

export default Hero;
